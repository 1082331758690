<template>
  <div>
    <navbar />
    <div class="flex justify-center items-center my-4 flex-col">
         <img class="h-40 sm:h-96" :src="`${$apiDevuelvoya}/images/shared/space.svg`" >
         <div class="my-4">
            <span class="text-xl text-blue-500"> !Ops! Por aqui no se ha encontrado nada</span>
         </div>
    </div>
  </div>
</template>

<script>
import baseUrl from '@/services/Api/BaseUrl';
import Navbar from '@/components/Core/Navbar/Navbar.vue';

export default {
  components: { Navbar },
  data() {
    return {
      BaseUrl: baseUrl.apiDevuelvoya,
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

</style>
