<template>
  <div class="navbar">
    <div class="navbar__logo">
      <img :src="BaseUrl + '/images/logo/devuelvoya.png'" />
    </div>
  </div>
</template>

<script>

import baseUrl from '@/services/Api/BaseUrl';

// @group Navbar
export default {
  data() {
    return {
      BaseUrl: baseUrl.apiDevuelvoya,
    };
  },
};
</script>

<style scoped>
.navbar {
  background: rgb(255, 255, 255);
  padding: 15px 5px;

  display: flex;
  flex-direction: row;
}

.navbar__logo {
  width: 9rem;
  margin: 0 10px;
}

.navbar__logo img {
  width: 100%;
}
</style>
